import React from "react";
import Services from "./Services";
import BusinessProcess from "./BusinessProcess";
import Contact from "../../Components/Contact";
import Navbar from "../../Components/Navbar";

export default function ServicePage() {
  return (
    <>
    <Navbar/>
      <Services />
      <BusinessProcess />
      <Contact />
    </>
  );
}
