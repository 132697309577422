import React from "react";

export default function BusinessProcess() {
  const business = [
    {
      heading: "Project Management",
      text: "We use a structured project management approach to ensure that our projects are completed on time, within budget, and to the satisfaction of our clients.",
      icons: ["bi bi-check2-square"],
    },
    {
      heading: "Quality Management",
      text: "We have established quality management processes that ensure that our services meet the highest standards of quality.",
      icons: ["bi bi-check2-square"],
    },
    {
      heading: "Communication",
      text: "We maintain open and transparent communication with our clients throughout the project lifecycle to ensure that they are fully informed about the progress of their project.",
      icons: ["bi bi-check2-square"],
    },
    {
      heading: "Technology",
      text: "We use a range of technology tools to support our data management services. These tools include data management platforms, data analytics tools, and cybersecurity software. We continually evaluate new technologies and tools to ensure that we are using the most effective solutions for our clients.",
      icons: [
        "bi bi-laptop",
        "bi bi-bar-chart-fill",
        "bi bi-shield-fill-check",
      ],
    },
    {
      heading: "Facilities",
      text: "We will have a modern office space that is equipped with the latest technology and tools. Our office space will be designed to promote collaboration and teamwork, and it provides a comfortable and productive environment for our team members.",
      icons: ["bi bi-building"],
    },
    {
      heading: "Risk Management",
      text: "We have established risk management processes that enable us to identify and mitigate risks associated with our projects. We work closely with our clients to understand their risk tolerance and ensure that our projects are designed to minimize risk.",
      icons: ["bi bi-exclamation-square-fill"],
    },
  ];
  return (
    <section id="featured-services" class="featured-services text-dark">
      <div class="container text-center">
        <h2 className="text-center">Business Process</h2>
        <p>
          We have developed a set of business
          processes that enable us to efficiently deliver our services to our
          clients. These processes include:
        </p>
        <div class="row gy-4">
          {business.map((item) => {
            return (
              <>
                <div class=" col-md-4 d-flex" data-aos="zoom-out">
                  <div class="service-item business-process-item position-relative border rounded-4">
                    <div class="icon">
                      <i class={item.icons}></i>
                    </div>
                    <h4 className="business-process-heading">

                        {item.heading}

                    </h4>
                    <p>{item.text}</p>
                  </div>
                </div>
              </>
            );
          })}
          <p className="mt-5">Overall, our operational plan is designed to ensure that we can
          efficiently and effectively deliver high quality data management
          services to our clients. Our focus on project management, quality
          management, communication, technology, facilities, and risk management
          enable us to deliver our services on time, within budget, and to the
          satisfaction of our clients.</p>
        </div>
      </div>
    </section>
  );
}
