import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadFiles from "./Components/UploadFiles";
import DownloadFiles from "./Components/DownloadFiles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import SingUp from "./Components/SingUp";
import Home from "./Pages/HomePage/Home";
import ContactPage from "./Pages/ContactUs/ContactPage";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import ServicePage from "./Pages/Services/ServicePage";
import Navbar from "./Components/Navbar";
import Govenment from "./Pages/Government/Govenment";
import DataProcessing from "./Pages/DataProcessing/DataProcessing";
import ProtectedRoute from "./Components/ProtectedRoute";
import UserSettings from "./Components/UserSettings";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Login/:status?" element={<Login />} />
        <Route path="/Singup" element={<SingUp />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/uploads"element={<UploadFiles />} />
          <Route path="/downloads" element={<DownloadFiles />} />
          <Route path="/settings" element={<UserSettings />} />
        </Route>
        <Route path="/services" element={<ServicePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/government-datasets" element={<Govenment />} />
        <Route path="/data-processing" element={<DataProcessing />} />
        {/* <Route path="/contact" element={<ContactPage />} /> */}
        {/* <Route path="/Dashboard" element={<DashboardMain />}>
          <Route path="/Dashboard/Content" element={<Content />} />
        </Route> */}
      </Routes>

      <Footer />
      <ScrollToTopButton />
    </Router>
  );
}

export default App;
