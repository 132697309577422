import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isCheckingUser, setIsCheckingUser] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      const storedUser = localStorage.getItem("token");

      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }

      setIsCheckingUser(false);
    };

    checkUser();
  }, []);

  const value = {
    user,
    isCheckingUser,
    setUser: (user) => {
      if (user) {
        localStorage.setItem("token", JSON.stringify(user));
      } else {
        localStorage.removeItem("token");
      }

      setUser(user);
    },
  };

  return (
    <UserContext.Provider value={value}>
      {!isCheckingUser && children}
    </UserContext.Provider>
  );
};

export default UserContext;