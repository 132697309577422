import React, { useState, useContext, useEffect } from "react";
import "../css/style.css";
import UserContext from "./UserContext";
import logo from "../images/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Navbar from "./Navbar";
function Login() {
  const navigate = useNavigate();
  const { status } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [accountCreated, setAccountCreated] = useState();
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (user) {
      navigate(-1);
    }

    if (status == "created") {
      setAccountCreated(
        "Your account has been successfully created. You can now log in using your email and password."
      );
    } else if (status == "expired") {
      setError("Your session has been expired.Kindly login again.");
    }
  }, [user, navigate, status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !email) {
      setError("Kindly fill all inputs");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/auth/login`,
        { email, password }
      );

      if (response.status == 200) {
        // const { token, userId } = response.data;
        // console.log(response.data);

        localStorage.setItem("token", null);
        localStorage.setItem("token", response.data.token);
        const decoded = jwtDecode(response.data.token);

        // Set user context
        setUser(decoded);
        console.log(decoded);
        // console.log("Logged In Data",response.data)
      } else {
        setError("Invalid Email or Password");
      }
      // Save the token and userId to the local storage or state management system
    } catch (err) {
      setError(err.response.data.error || "Error while logging in");
    }
  };

  return (
    <div style={{ backgroundColor: "#2D3E50" }}>
      <Navbar />
      <div className="pt-5 mt-5">
        <div class="container ">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col col-md-7 col-10 mb-5">
              <div class="card" style={{ borderRadius: "1rem" }}>
                <div class="row g-0 w-100">
                  <div class="col-11 mx-auto  d-flex align-items-center">
                    <div class="card-body text-black">
                      <form onSubmit={handleSubmit}>
                        <div class="d-flex align-items-center text-center mb-3 py-3">
                          <img
                            src={logo}
                            width={"100px"}
                            className="mx-auto"
                          ></img>
                          <span class="h1 fw-bold mb-0"></span>
                        </div>
                        {error && (
                          <div class="d-flex align-items-center">
                            <div className="alert alert-danger w-100">
                              {error}
                            </div>
                          </div>
                        )}
                        {accountCreated && (
                          <div class="d-flex align-items-center">
                            <div className="alert alert-success w-100">
                              {accountCreated}
                            </div>
                          </div>
                        )}
                        <h5
                          class="fw-normal mb-3 pb-2 text-center"
                          style={{ letterSpacing: "1px" }}
                        >
                          <b> Sign into your account</b>
                        </h5>

                        <div class="form-outline mb-3">
                          <label class="form-label" for="form2Example17">
                            Email address
                          </label>
                          <input
                            type="email"
                            id="form2Example17"
                            class="form-control form-control-md"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div class="form-outline mb-3">
                          <label
                            htmlFor="form2Example27"
                            className="form-label"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="form2Example27"
                              className="form-control form-control-md"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <span role="button"
                              className="btn btn-outline-secondary"
                              onClick={toggleShowPassword}
                            >
                              {showPassword ? (
                                <i className="bi bi-eye-slash"></i>
                              ) : (
                                <i className="bi bi-eye"></i>
                              )}
                            </span>
                          </div>
                          {/* <div className="mt-2 d-flex justify-content-end">
                            <a class="small text-muted" href="#!">
                              Forgot password?
                            </a>
                          </div> */}
                        </div>

                        <div class="pt-1 text-center">
                          <button type="submit" className="btn btn-full">
                            Login
                          </button>
                        </div>
                        <p class="my-3 " style={{ color: "#393f81" }}>
                          Don't have an account?{" "}
                          <Link
                            to="/Singup"
                            className="text-decoration-underline"
                            style={{ color: "#393f81" }}
                          >
                            Register here
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
