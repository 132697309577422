import React, { useState, useEffect } from "react";
import UserContext from "./Components/UserContext";
import App from "./App";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function AppWrapper() {
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to get the user data
      const decoded = jwtDecode(token);

      // Check if the token is expired
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("token");
        console.log("Token is expired");
        setUser({});
        window.location.reload("/login/expired");
      } else {
        setUser(decoded);
        console.log("Decoded User", user);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <App />
    </UserContext.Provider>
  );
}

export default AppWrapper;
