import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function Hero() {
  return (
    <section id="hero-animated" class="hero-animated d-flex align-items-center">
      <div
        class="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
        data-aos="zoom-out"
      >
        <img
          src="img/hero-carousel/hero-carousel-3.svg"
          class="img-fluid animated"
        />
        <h2>
          Welcome to <span>PreEmpt</span>
        </h2>
        <p>
          Welcome to Preempt, a trusted partner for businesses looking to do
          business with the US Government. Our team of experts has years of
          experience working with federal agencies and has developed the
          expertise to navigate the complexities of working with the government.
          We offer a range of specialized services, including data management,
          cybersecurity, and data analytics, that can help your business succeed
          in the government marketplace.
        </p>
      </div>
    </section>
  );
}
