import React from "react";
import { Link } from "react-router-dom";

export default function GovDatasets() {
  return (
    <section id="onfocus" class="onfocus">
      <div class="container-fluid p-0" data-aos="fade-up">
        <div class="row g-0" style={{ maxWidth: "100%" }}>
          <div class="col-lg-6 video-play position-relative">

          </div>
          <div class="col-lg-6">
            <div class="content d-flex flex-column justify-content-center h-100">
              <h3>Government Datasets</h3>
              <p class="fst-italic text-light">
                At Preempt, we provide access to a variety of government
                datasets that businesses can use to inform their decision-making
                processes. We offer datasets across a range of topics, including
                healthcare, education, energy, and finance, and we work closely
                with our clients to identify the most relevant datasets for
                their needs.
              </p>

              <Link to="/government-datasets" class="read-more align-self-start">
                <span>Read More</span>
                <i class="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
