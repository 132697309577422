import React from "react";
import { Link } from "react-router-dom";
export default function DataProcessing() {
  return (
    <section id="onfocus" class="onfocus">
      <div class="container-fluid p-0" data-aos="fade-up">
        <div class="row g-0" style={{ maxWidth: "100%" }}>
          <div class="col-lg-6">
            <div class="content d-flex flex-column justify-content-center h-100">
              <h3>Data Processing</h3>
              <p class="fst-italic text-light">
                At Preempt, we offer data processing services to businesses that
                need to analyze large volumes of data quickly and accurately.
                Our team of data processing experts uses advanced tools and
                techniques to process data quickly and accurately, helping our
                clients to make data-driven decisions in real-time.
              </p>

              <Link to="/data-processing" class="read-more align-self-start">
                <span>Read More</span>
                <i class="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-6 data-process position-relative"></div>
        </div>
      </div>
    </section>
  );
}
