import React, { useState, useContext, useEffect } from "react";
import UserContext from "./UserContext";
import Navbar from "./Navbar";
import Contact from "./Contact";
import axios from "axios";
function UserSettings() {
  const { user, setUser } = useContext(UserContext);

  const [formData, setFormData] = useState({
    userName: user?.name || "",
    email: user?.email || "",
    oldPassword: "",
    oldPassword1: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_DOMAIN}/api/auth/current-user`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUser({
          ...response.data,
          name: response.data.userName,
        });

        setFormData({
          ...formData,
          userName: response.data.userName,
          email: response.data.email,
        });
      } catch (error) {
        console.error("Error fetching current user data:", error);
      }
    };

    fetchUserData();
  }, []);
  const [error, setError] = useState("");
  const [infoerror, setinfoError] = useState("");
  const [infosuccess, setinfosuccess] = useState("");
  const [passsuccess, setpasssuccess] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showOldPassword1, setShowOldPassword1] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();
    const email = formData.email;
    const name = formData.userName;
    const password = formData.oldPassword;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN}/api/auth/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userName: name,
            email: email,
            oldPassword: password,
          }),
        }
      );

      if (response.ok) {
        setUser({
          ...user,
          email: email,
          name: name,
        });
        setinfosuccess("User Information Updated");
        setinfoError("");
      } else {
        const data = await response.json();
        setinfoError(data.error || "Error updating user information");
        setinfosuccess("");
      }
    } catch (error) {
      console.error("Error:", error);
      setinfoError("Error updating user information");
      setinfosuccess("");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!formData.newPassword || !formData.confirmPassword) {
      setError("Input fields can't be empty");
      setpasssuccess("");

      return;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New passwords do not match");
      setpasssuccess("");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN}/api/auth/change-password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            oldPassword: formData.oldPassword1,
            newPassword: formData.newPassword,
          }),
        }
      );

      if (response.ok) {
        setpasssuccess("Password updated");
        setError("");
      } else {
        const data = await response.json();
        setError(data.error || "Error updating password");
        setpasssuccess("");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error updating password");
      setpasssuccess("");
    }
  };

  return (
    <div className="container-fluid">
      <Navbar />
      <h2 className="mt-5 pt-5">User Settings</h2>
      <form onSubmit={handleInfoSubmit} className="col-md-6 mx-auto">
        <h4>Update Email and Username</h4>
        <label htmlFor="userName">Company:</label>
        <input
          className="form-control"
          type="text"
          id="userName"
          value={user?.company?.name ? user?.company?.name : user?.company}
          onChange={() => {}}
          disabled
        />
        <label htmlFor="userName">Username:</label>
        <input
          className="form-control"
          type="text"
          id="userName"
          value={formData.userName || user?.name}
          onChange={handleChange}
        />

        <label htmlFor="email">Email:</label>
        <input
          className="form-control"
          type="email"
          id="email"
          value={formData.email || user?.email}
          onChange={handleChange}
        />

        <label htmlFor="oldPassword">Current Password:</label>
        <div className="input-group mb-3">
          <input
            className="form-control"
            id="oldPassword"
            type={showOldPassword1 ? "text" : "password"}
            value={formData.oldPassword}
            onChange={handleChange}
          />
          {/* Show/hide password icon */}
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setShowOldPassword1(!showOldPassword1)}
          >
            {showOldPassword ? (
              <i className="bi bi-eye"></i>
            ) : (
              <i className="bi bi-eye-slash"></i>
            )}
          </button>
        </div>

        {infoerror && <p className="text-danger">{infoerror}</p>}
        {infosuccess && <p className="text-success">{infosuccess}</p>}
        <div className="d-flex ">
          <button type="submit" className="btn btn-full my-2 mx-auto">
            Update Info
          </button>
        </div>
      </form>

      <form onSubmit={handlePasswordSubmit} className="col-md-6 mx-auto mt-4">
        <h4>Change Password</h4>
        <label htmlFor="newPassword">New Password:</label>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
          />
          {/* Show/hide password icon */}
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <i className="bi bi-eye"></i>
            ) : (
              <i className="bi bi-eye-slash"></i>
            )}
          </button>
        </div>

        <label htmlFor="confirmPassword">Confirm New Password:</label>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {/* Show/hide password icon */}
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <i className="bi bi-eye"></i>
            ) : (
              <i className="bi bi-eye-slash"></i>
            )}
          </button>
        </div>

        <label htmlFor="oldPassword">Current Password:</label>
        <div className="input-group mb-3">
          <input
            className="form-control"
            type={showOldPassword ? "text" : "password"}
            id="oldPassword1"
            value={formData.oldPassword1}
            onChange={handleChange}
          />
          {/* Show/hide password icon */}
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setShowOldPassword(!showOldPassword)}
          >
            {showOldPassword ? (
              <i className="bi bi-eye"></i>
            ) : (
              <i className="bi bi-eye-slash"></i>
            )}
          </button>
        </div>
        {error && <p className="text-danger">{error}</p>}
        {passsuccess && <p className="text-success">{passsuccess}</p>}
        <div className="d-flex ">
          <button type="submit" className="btn btn-full my-2 mx-auto">
            Change Password
          </button>
        </div>
      </form>
      <Contact />
    </div>
  );
}

export default UserSettings;
