import React, { useState, useContext, useEffect } from "react";
import "../css/style.css";
import { FileUploader } from "react-drag-drop-files";
import { Player } from "@lottiefiles/react-lottie-player";
import Navbar from "./Navbar";
import axios from "axios";
import UserContext from "./UserContext";
import jwtDecode from "jwt-decode";

function UploadFiles() {
  const [file, setFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [success, setSuccess] = useState();
  const [sizeError,setSizeError] = useState();
  const [error, setError] = useState();
  const [isActive, setisActive] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState("");
  useEffect(() => {
    const checkActiveStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/auth/is-active`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setisActive(response.data.isActive);
        console.log("Status", response.data.isActive);
      } catch (error) {
        console.error("Error checking active status:", error);
      }
    };
    const fetchContracts = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/contracts`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setContracts(res.data.contracts);
        console.log(res);
        console.log("Contracts", res.data.contracts[0].contract);
      } catch (err) {
        console.error("Error fetching contracts:", err);
      }
    };

    fetchContracts();

    checkActiveStatus();
  }, []);

  const handleChange = (selectedFiles) => {
    setSizeError("");
    setFile((prevFiles) => [...prevFiles, ...Array.from(selectedFiles)]);
  };

  const removeFile = (indexToRemove) => {
    setFile(file.filter((_, index) => index !== indexToRemove));
  };
  const handleContractChange = (event) => {
    setSelectedContract(event.target.value);
  };
  const startUpload = async (e) => {
    e.preventDefault();
    if (file?.length == 0) {
      setError("No File(s) to upload");
      return;
    }
    if (!selectedContract) {
      setError("Please select a contract.");
      setSuccess("");
      return;
    } else {
      setError("");
    }
    setIsUploading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      file.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      formData.append("contract", selectedContract);

      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Contract: selectedContract,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 90) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      setSuccess("File(s) uploaded successfully");
      setError("");
      setIsUploading(false);
      // Clear the FormData object and reset the file state
      formData.delete("file");
      setFile([]);
      setUploadProgress(100);
      setTimeout(() => {
        setUploadProgress(0);
      }, 1000);
      console.log(res);
    } catch (err) {
      setError("Error uploading file(s)");
      setSuccess("");
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const fileTypes = ["csv", "xlsx", "docx", "zip"];
  return (
    <div>
      <Navbar />

      <header style={{ backgroundColor: "#2D3E50" }}>
        <div class="hero-textbox">
          {/* <h1 style={{ color: "#3E36A4" }}>
            Upload your files and get downloaded at anytime
          </h1> */}
          <Player
            src="https://assets5.lottiefiles.com/packages/lf20_jvkRrK.json"
            className="player"
            loop
            autoplay
            style={{ height: "500px", width: "500px" }}
          />
          {/* <a class="btn btn-full" href="#">
            Upload Files
          </a>
          <a class="btn btn-ghost" href="#">
            Download Now
          </a> */}
        </div>
      </header>
      <div className="d-flex justify-content-center filesection card p-4">
        {isActive === null ? null : isActive ? (
          <>
            <h6 style={{ color: "#2D3E50" }} className="mb-3">
              Drag & Drop Your Files
            </h6>
            <FileUploader
              key={file.length}
              multiple={true}
              maxSize={5}
              onSizeError={()=>setSizeError("File size is over 5 MB")}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />
            {sizeError?<p className="text-danger">{sizeError}</p>:""}
            {file?.length === 0 ? (
              ""
            ) : (
              <table className="table bordered table-bordered mt-3 mx-auto">
                <tr>
                  <th>File name</th>
                  <th>Action</th>
                </tr>
                {file?.map((file, index) => (
                  <>
                    <tr key={index} className="my-2">
                      <td>{file.name}</td>
                      <td className="text-center">
                        <i
                          onClick={() => removeFile(index)}
                          role="button"
                          class="mx-auto text-danger bi bi-x-circle"
                        ></i>
                      </td>
                    </tr>
                  </>
                ))}
              </table>
            )}
            <div className="my-3 ">
              <label htmlFor="contract-select" className="form-label">
                Select a contract
              </label>
              <select
                className="form-select"
                id="contract-select"
                onChange={handleContractChange}
                value={selectedContract}
              >
                <option value="" disabled>
                  Select a contract
                </option>
                {Array.isArray(contracts) &&
                  contracts.map((contract) => (
                    <option key={contract.id} value={contract.id}>
                      {contract.contract}
                    </option>
                  ))}
              </select>
            </div>
            {error && <p className="text-danger">{error}</p>}
            {success && <p className="text-success">{success}</p>}

            {!isUploading && (
              <a class="btn btn-full" href="#" onClick={startUpload}>
                Upload Files
              </a>
            )}
            {isUploading && (
              <div className="progress mt-3 w-100">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            )}
          </>
        ) : (
          <p className="text-danger">
            Your account is currently inactive. To activate your account, please
            contact the administration team for further assistance.
          </p>
        )}
      </div>
    </div>
  );
}
export default UploadFiles;
