// import React from 'react'
import logo from "../images/logo.png";
import UserContext from "./UserContext";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const isActive = (menuPath) => {
    return path === menuPath ? "menuactive" : "";
  };

  const handleLogout = () => {
    // Clear the user session and redirect to the login page
    localStorage.removeItem("token");

    setUser(null);
    // Redirect to the login page, replace "/login" with your login route
    navigate("/");
  };

  return (
    <>
      <nav class="navbar fixed-top container-fluid navbar-expand-lg bg-white shadow-sm">
        <div class="container py-2 ">
          <Link class="navbar-brand" to="/">
            <img src={logo} width={"100px"}></img>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse " id="navbarNav">
            <ul className="navbar-nav mx-auto mb-2 w-100 mb-lg-0 nav-fill">
              <li className={`nav-item mx-1 ${isActive("/")}`}>
                <Link exact to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className={`nav-item mx-1 ${isActive("/services")}`}>
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li
                className={`nav-item mx-1 ${isActive("/government-datasets")}`}
              >
                <Link to="/government-datasets" className="nav-link">
                  Government Datasets
                </Link>
              </li>
              <li className={`nav-item mx-1 ${isActive("/data-processing")}`}>
                <Link to="/data-processing" className="nav-link">
                  Data Processing
                </Link>
              </li>
              <li className={`nav-item mx-1 ${isActive("/contact")}`}>
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
              {/* {user?
                <li className="nav-item mx-1">
                  <Link to="/downloads" className="btn btn-full">
                    Downloads
                  </Link>
                </li>:


                } */}
              {user ? (
                <div class="dropdown ">
                  <button
                    class="btn btn-light dropdown-toggle btn btn-full rounded-5"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user.name}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <Link to="/uploads" className="dropdown-item">
                        <span>
                          {" "}
                          <i class="bi bi-upload"></i> Upload File
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/downloads" className="dropdown-item">
                        <span>
                          <i class="bi bi-box-arrow-down"></i> Downloads
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link class="dropdown-item" to="/settings">
                        <span>
                          <i class="bi bi-gear"></i> Settings
                        </span>
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        onClick={() => handleLogout()}
                      >
                        <i class="bi bi-box-arrow-left"></i> Logout
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <li className="nav-item mx-1">
                  <Link to="/login" className="btn btn-full">
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
