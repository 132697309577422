import React from "react";
import Navbar from "../../Components/Navbar";
import Contact from "../../Components/Contact";
import Footer from "../../Components/Footer";

export default function ContactPage() {
  return (
    <>
      <Navbar />
      <Contact />
     
    </>
  );
}
