import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import UserContext from "./UserContext";
import jwtDecode from "jwt-decode";

const ProtectedRoute = () => {
  const { setUser } = useContext(UserContext);
  const token = localStorage.getItem("token");
  if (token) {
    // Decode the token to get the user data
    const decoded = jwtDecode(token);

    // Check if the token is expired
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem("token");
      console.log("Token is expired");
      setUser({});
      return <Navigate to="/login/expired" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
