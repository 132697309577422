import React, { useState } from "react";
import axios from "axios";
export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const handleSubjectChange = (e) => {
    const input = e.target.value;
    if (input.length <= 200) {
      setSubject(input);
    }
  };

  const handleMessageChange = (e) => {
    const input = e.target.value;
    if (input.length <= 200) {
      setMessage(input);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/contact/save`,
        {
          name,
          email,
          company,
          subject,
          message,
        }
      );

      alert("Message sent successfully.");
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message");
    }
  };
  return (
    <section id="contact" class="contact main-bg text-light ">
      <div class="container pt-4">
        <div class="section-header text-light">
          <h2 className="text-light">Contact Us</h2>
          <p className="text-light">
            If you have any questions or would like to learn more about how
            Preempt can help your business succeed in the government
            marketplace, please contact us. We look forward to hearing from you
            and working with you to achieve your goals.
          </p>
        </div>
      </div>

      <div class="container">
        <div class="row gy-5 gx-lg-5">
          <div class="col-lg-4 ">
            <div class="info bg-light shadow-lg rounded-3">
              <h3 className="text-dark">Get in touch</h3>
              <p>We're just a click away. Contact us now!</p>

              <div class="info-item d-flex">
                <i class="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>Tampa, FL </p>
                </div>
              </div>

              <div class="info-item d-flex">
                <i class="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>jack@pre-empt.io</p>
                </div>
              </div>

              <div class="info-item d-flex">
                <i class="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+1-727-614-1143</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <form
              onSubmit={handleSubmit}
              method="post"
              role="form"
              class="php-email-form bg-light shadow-lg rounded-3 p-4"
            >
              <div class="row">
                <div class="col-md-6  form-group ps-0 contact-input">
                  <input
                    type="text"
                    name="name"
                    class="form-control col-12 "
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0 pe-0 contact-input">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="company"
                  id="company"
                  placeholder="Company"
                  value={company}
                  onChange={(e)=>setCompany(e.target.value)}
                  maxLength={200}
                />

              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={handleSubjectChange}
                  maxLength={200}
                  required
                />
                <small className="text-muted">
                  {subject.length}/{200}
                </small>
              </div>
              <div class="form-group mt-3">
                <textarea
                  class="form-control"
                  name="message"
                  placeholder="Message"
                  value={message}
                  onChange={handleMessageChange}
                  maxLength={200}
                  required
                ></textarea>
                <small className="text-muted">
                  {message.length}/{200}
                </small>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
