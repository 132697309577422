import { Player } from "@lottiefiles/react-lottie-player";
import * as React from "react";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";
import logo from "../images/logo.png";
import "../css/style.css";
import Navbar from "./Navbar";
import UserContext from "./UserContext";
import io from "socket.io-client";

function DownloadFiles() {
  const [files, setFiles] = useState([]);
  const [isActive, setisActive] = useState(null);
  const [isLoading, setisLoading] = useState(true); // Initially set isLoading to true
  const [socket, setSocket] = useState(null);
  const { user } = useContext(UserContext);

  const setupSocket = () => {
    const token = localStorage.getItem("token");
    const newSocket = io(process.env.REACT_APP_DOMAIN, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    newSocket.on("transformedFileChange", () => {
      fetchFiles();
    });

    setSocket(newSocket);
  };

  useEffect(() => {
    const checkActiveStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/auth/is-active`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setisActive(response.data.isActive);
      } catch (error) {
        console.error("Error checking active status:", error);
      }
    };

    checkActiveStatus();
  }, []);

  useEffect(() => {
    if (isActive) {
      if (!socket) {
        setupSocket();
      }
      fetchFiles();

      // Clean up the listener when the component is unmounted
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [isActive]);

  const fetchFiles = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/user/files`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFiles(response.data);
      setisLoading(false); // Set isLoading to false when the files are fetched
      console.log(response.data);
    } catch (err) {
      setisLoading(false); // Set isLoading to false in case of an error
      console.error("Error fetching user files:", err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#2D3E50",
          paddingBottom: "50px",
          marginTop: "80px",
        }}
      >
        <Navbar />

        <div className="pt-4">
          <Player
            src="https://assets2.lottiefiles.com/packages/lf20_kabycqad.json"
            className="player"
            loop
            autoplay
            style={{ height: "300px", width: "300px" }}
          />
        </div>
        <div className="d-flex justify-content-center ">
          <div
            style={{ width: "80%" }}
            className="p-4 tablebox table-responsive rounded-3"
          >
            {isActive === null ? null : isActive ? (
              <table
                className="table table-bordered table-sm table-hover"
                style={{ textAlign: "center" }}
              >
                <thead>
                  <tr className="head">
                    <th scope="col">#</th>
                    <th scope="col">Uploaded By</th>
                    <th scope="col">Company</th>
                    <th scope="col">Contract</th>
                    <th scope="col">Status</th>
                    <th scope="col">Uploaded File</th>
                    <th scope="col">Transformed File</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, index) => (
                    <tr key={file._id}>
                      <td>{index + 1}</td>
                      <td>{file.userName}</td>
                      <td>{file.companyName}</td>
                      <td>{file.contractName}</td>
                      <td>{file.status}</td>
                      <td>
                        <a
                          href={file.originalFileUrl}
                          className=""
                          download={file.uploadedFileName}
                        >
                          {file.uploadedFileName}
                        </a>
                        <br />
                        <small>
                          ({formatDate(file.uploadedFileCreatedAt)})
                        </small>
                      </td>
                      <td>
                        {file.transformedFileName ? (
                          <>
                            <a
                              href={file.fileUrl}
                              className=""
                              download={file.transformedFileName}
                            >
                              {file.transformedFileName}
                            </a>
                            <br />
                            <small>
                              ({formatDate(file.transformedFileCreatedAt)})
                            </small>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-danger">
                Your account is currently inactive. To activate your account,
                please contact the administration team for further assistance.
              </p>
            )}
            {!isLoading && files.length === 0 ? ( // Only show the loading text when isLoading is true and files are empty
              <p className="text-muted text-center font-italic">
                No file available to download.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadFiles;
