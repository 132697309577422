import React from "react";

export default function FeaturedServices() {
  return (
    <section id="featured-services" class="featured-services">
      <div class="container">
        <h2 className="text-center main-color">What We Offer?</h2>
        <div class="row gy-4">
          <div class=" col-md-4 d-flex" data-aos="zoom-out">
            <div class="service-item position-relative">
              <div class="icon">
              <i class="bi bi-pc-display"></i>
              </div>
              <h4>
                <a href="" class="stretched-link text-decoration-none">
                  Digital Services
                </a>
              </h4>
              <p>
                Innovative, ethical digital solutions to accelerate business
                value. Stay ahead of the competition with Preempt.
              </p>
            </div>
          </div>

          <div
            class=" col-md-4 d-flex"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <div class="service-item position-relative">
              <div class="icon">
              <i class="bi bi-buildings-fill"></i>
              </div>
              <h4>
                <a href="" class="stretched-link text-decoration-none">
                  Government Datasets
                </a>
              </h4>
              <p>
                Providing specialized services in data management,
                cybersecurity, and data analytics to US Government.
              </p>
            </div>
          </div>

          <div
            class=" col-md-4 d-flex"
            data-aos="zoom-out"
            data-aos-delay="400"
          >
            <div class="service-item position-relative">
              <div class="icon">
              <i class="bi bi-database-fill-gear"></i>
              </div>
              <h4>
                <a href="" class="stretched-link text-decoration-none">
                  Data Processing
                </a>
              </h4>
              <p>
                Preempt helps clients develop data management strategies and
                implements data architecture designs
              </p>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}
