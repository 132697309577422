import React, { useState, useContext, useEffect } from "react";
import "../css/style.css";
import logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import UserContext from "./UserContext";
import Navbar from "./Navbar";

function SignUp() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.password ||
      !formData.confirmPassword ||
      !formData.email ||
      !formData.userName
    ) {
      setError("Kindly fill all inputs");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN}/api/auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userName: formData.userName,
            email: formData.email,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
          }),
        }
      );

      if (response.ok) {
        navigate("/login/created");
        // Redirect to login page or any other page you prefer
      } else {
        const data = await response.json();
        setError(data.error || "Error creating user");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error creating user");
    }
  };

  return (
    <div style={{ backgroundColor: "#2D3E50" }}>
      <Navbar />
      <div className="pt-5 mt-5">
        <div class="container ">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col col-md-7 col-10 mb-5">
              <div class="card" style={{ borderRadius: "1rem" }}>
                <div class="row g-0 w-100">
                  <div class="col-12 mx-auto d-flex align-items-center">
                    <div class="card-body p-3 text-black">
                      <form onSubmit={handleSubmit}>
                        <div class="d-flex align-items-center mb-3 pb-3">
                          <img
                            src={logo}
                            width={"100px"}
                            className="mx-auto"
                          ></img>
                          <span class="h1 fw-bold mb-0"></span>
                        </div>
                        {error && (
                          <div class="d-flex align-items-center">
                            <div className="alert alert-danger w-100">
                              {error}
                            </div>
                          </div>
                        )}

                        <h5
                          class="fw-normal mb-3 text-center pb-2"
                          style={{ letterSpacing: "1px" }}
                        >
                          <b>Create your account</b>
                        </h5>
                        <div className="row">
                          <div class="form-outline mb-3 col-md-6">
                            <label class="form-label" for="userName">
                              User Name
                            </label>
                            <input
                              type="text"
                              id="userName"
                              onChange={handleChange}
                              class="form-control form-control-md"
                            />
                          </div>

                          <div class="form-outline mb-3 col-md-6">
                            <label class="form-label" for="email">
                              Email address
                            </label>
                            <input
                              type="email"
                              id="email"
                              onChange={handleChange}
                              class="form-control form-control-md"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div class="form-outline mb-3 col-md-6">
                            <label class="form-label" for="password">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                onChange={handleChange}
                                className="form-control form-control-md"
                              />
                              {/* Show/hide password icon */}
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <i
                                  className={
                                    showPassword
                                      ? "bi bi-eye"
                                      : "bi bi-eye-slash"
                                  }
                                ></i>
                              </button>
                            </div>
                          </div>

                          <div class="form-outline mb-3 col-md-6">
                            <label class="form-label" for="confirmPassword">
                              Confirm Password
                            </label>
                            <div className="input-group">
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                onChange={handleChange}
                                className="form-control form-control-md"
                              />
                              {/* Show/hide password icon */}
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                <i
                                  className={
                                    showConfirmPassword
                                      ? "bi bi-eye"
                                      : "bi bi-eye-slash"
                                  }
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="pt-1 text-center">
                          <button class=" btn  btn-full">Sign Up</button>
                        </div>
                        <p class="my-3 " style={{ color: "#393f81" }}>
                          Already have an account?{" "}
                          <Link
                            to="/login"
                            className="text-decoration-underline"
                            style={{ color: "#393f81" }}
                          >
                            Login here
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
