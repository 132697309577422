import React from "react";
import { Link } from "react-router-dom";
export default function Services() {
  return (
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Services and Solutions</h2>
          <p>
            At Preempt, we offer a range of specialized services to businesses
            looking to do business with the US Government. Our services include:
          </p>
        </div>

        <div class="row gy-5">
          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-1.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-ubuntu"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>Data Strategy Development</h3>
                </a>
                <p>
                  We work with our clients to develop data management strategies
                  that align with their organizational goals and objectives.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-2.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-diagram-3"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>Data Architecture Design</h3>
                </a>
                <p>
                  We design and implement data architectures that enable our
                  clients to efficiently store, manage, and access their data.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-3.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-key"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>Data Governance</h3>
                </a>
                <p>
                  We help our clients establish data governance policies and
                  procedures that ensure compliance with federal regulations and
                  policies.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-4.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-bounding-box-circles"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>
                    Data <br /> Integration
                  </h3>
                </a>
                <p>
                  We integrate disparate data sources into a unified data
                  architecture that provides our clients with a single source of
                  truth for their data.
                </p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-5.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-kanban"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>Data Quality Management</h3>
                </a>
                <p>
                  We implement data quality management processes and tools that
                  enable our clients to maintain the accuracy and completeness
                  of their data.
                </p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="700"
          >
            <div class="service-item">
              <div class="img">
                <img src="img/services-6.jpg" class="img-fluid" alt="" />
              </div>
              <div class="details position-relative">
                <div class="icon">
                  <i class="bi bi-graph-up-arrow"></i>
                </div>
                <a href="#" class="stretched-link">
                  <h3>
                    Data <br /> Analytics
                  </h3>
                </a>
                <p>
                  We provide data analytics services that enable our clients to
                  extract insights and make data-driven decisions.
                </p>
                <a href="#" class="stretched-link"></a>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <Link to="/services" class="read-more">
              <span className="btn  mx-auto main-bg text-light">
                Explore More Services<i class="bi bi-arrow-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
