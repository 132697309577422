import React from "react";
export default function Services() {

    const services=[
        {
          heading: "Data Strategy Development",
          text: "We work with our clients to develop data management strategies that align with their organizational goals and objectives.",
          icons: "bi bi-lightbulb"
        },
        {
          heading: "Data Architecture Design",
          text: "We design and implement data architectures that enable our clients to efficiently store, manage, and access their data.",
          icons: "bi bi-layers"
        },
        {
          heading: "Data Governance",
          text: "We help our clients establish data governance policies and procedures that ensure compliance with federal regulations and policies.",
          icons: "bi bi-file-earmark-lock"
        },
        {
          heading: "Data Integration",
          text: "We integrate disparate data sources into a unified data architecture that provides our clients with a single source of truth for their data.",
          icons: "bi bi-arrows-angle-contract"
        },
        {
          heading: "Data Quality Management",
          text: "We implement data quality management processes and tools that enable our clients to maintain the accuracy and completeness of their data.",
          icons: "bi bi-check-circle"
        },
        {
          heading: "Data Analytics",
          text: "We provide data analytics services that enable our clients to extract insights and make data-driven decisions.",
          icons: "bi bi-bar-chart"
        },
        {
          heading: "Cloud Data Management",
          text: "We help our clients migrate their data to the cloud and provide ongoing cloud data management services.",
          icons: "bi bi-cloud-upload"
        },
        {
          heading: "Cybersecurity",
          text: "We implement cybersecurity measures that protect our clients' data from unauthorized access and cyber threats.",
          icons: "bi bi-shield-lock"
        }
      ]

  return (
    <section class="section-big main-color text-dark mt-5" >
      <div class="container" >
        <div class="row">
          <div class="col-md-12 pb-20 text-center" data-aos="zoom-out">
            <h2 class="section-title mb-10">
              <span>Services And Solutions</span>
            </h2>
            <p class="section-sub-title  my-3">
              At Preempt, we offer a range of specialized services to businesses
              looking to do business with the US Government.Our services are
              tailored to meet the specific needs of each client, and we work
              closely with our clients to ensure that their data management
              strategies align with their organizational goals.
            </p>
            <div class="exp-separator center-separator">
              <div class="exp-separator-inner"></div>
            </div>
          </div>
        </div>
        <div class="row" >

            {
                services.map((item)=>{

                    return(
                        <div class="col-md-4 services" data-aos="zoom-in">
                        <ul class="i-list medium">
                          <li class="i-list-item">
                            <div class="icon">
                              {" "}
                              <i class={item.icons}></i>{" "}
                            </div>

                            <div class="icon-content">
                              <h3 class="title fw-bold">{item.heading}</h3>
                              <p class="sub-title">
                               {item.text}
                              </p>
                            </div>
                            <div class="iconlist-timeline"></div>
                          </li>

                        </ul>
                      </div>
                    )
                })
            }

        </div>
      </div>
    </section>
  );
}
