import React from "react";
import Contact from "../../Components/Contact";
import Navbar from "../../Components/Navbar";

export default function Govenment() {
  return (
    <>
    <Navbar/>
    <section id="cta" class="cta " style={{ marginTop: "150px", }}>
      <div class="container aos-init aos-animate text-light" data-aos="zoom-out" style={{backgroundColor:'rgb(45, 62, 80)'}}>
        <div class="row g-5">
          <div data-aos="zoom-in-down" class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
            <h3 className="text-light">Government Datasets</h3>
            <p className="text-light">
              At Preempt, we provide access to a variety of government datasets
              that businesses can use to inform their decision-making processes.
              We offer datasets across a range of topics, including healthcare,
              education, energy, and finance, and we work closely with our
              clients to identify the most relevant datasets for their needs.
            </p>
            <a class="cta-btn align-self-start" href="#contact">
              Contact Now
            </a>
          </div>

          <div data-aos="zoom-in-left" class="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
            <div class="img">
              <img src="img/gov-data.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact/>
    </>
  );
}
