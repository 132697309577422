import React from "react";
import Contact from "../../Components/Contact";
import Navbar from "../../Components/Navbar";
export default function DataProcessing() {
  return (
    <>
    <Navbar/>
    <section id="cta" class="cta " style={{ marginTop: "150px" }}>
      <div
        class="container aos-init aos-animate text-light"
        data-aos="zoom-out"
        style={{ backgroundColor: "rgb(45, 62, 80)" }}
      >
        <div class="row g-5">
          <div
            data-aos="zoom-in-left"
            class="col-lg-4  col-md-6 order-first order-md-last d-flex align-items-center"
          >
            <div class="img">
              <img src="img/data-process-page.jpg" alt="" class="img-fluid" />
            </div>
          </div>
          <div
            data-aos="zoom-in-right"
            class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first"
          >
            <h3 className="text-light">Data Processing</h3>
            <p className="text-light">
              At Preempt, we offer data processing services to businesses that
              need to analyze large volumes of data quickly and accurately. Our
              team of data processing experts uses advanced tools and techniques
              to process data quickly and accurately, helping our clients to
              make data-driven decisions in real-time.
            </p>
            <a class="cta-btn align-self-start" href="#contact">
              Contact Now
            </a>
          </div>
        </div>
      </div>
    </section>
     <Contact />
     </>
  );
}
