import React from "react";

export default function OurTeam() {
  return (
    <section id="team" class="team">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Our Team</h2>
          <p>
            Our team is focused on delivering innovative, ethical, and
            high-quality technology solutions that help our clients stay ahead
            of the competition.We work closely with our clients to
            understand their needs and deliver results.
          </p>
        </div>

        {/* <div class="row gy-5">
          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="team-member col-12">

              <div class="member-info w-100">
                <div class="social">
                  <a href="">
                    <i class="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="team-member col-12">

              <div class="member-info w-100">
                <div class="social">
                  <a href="">
                    <i class="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>
          <div
            class="col-xl-4 col-md-6"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="team-member col-12">

              <div class="member-info w-100">
                <div class="social">
                  <a href="">
                    <i class="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>




        </div> */}
      </div>
    </section>
  );
}
