import React from "react";
import Hero from "./Hero";
import Navbar from "../../Components/Navbar";
import FeaturedServices from "./FeaturedServices";
import Services from "./Services";
import GovDatasets from "./GovDatasets";
import DataProcessing from "./DataProcessing";
import Testimonials from "./Testimonials";
import OurTeam from "./OurTeam";
import Contact from "../../Components/Contact";

export default function Home() {
  return (
    <>
    <Navbar/>
      <Hero />
      <FeaturedServices />
      <Services/>
      <GovDatasets/>
      <DataProcessing/>
      {/* <Testimonials/> */}
      <OurTeam/>
      <Contact/>
    </>
  );
}
